import '../sass/site.scss';
import $ from 'jquery';
import { Modal, Tooltip } from 'bootstrap';
import '@fancyapps/fancybox';
import Glide from '@glidejs/glide';
import 'jquery-toast-plugin';


window.$ = window.jQuery = $;

$('.custom-style').on('click', function (e) {
    e.preventDefault();
    $.toast({
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper posuere metus, eget maximus purus. ",
        heading: 'Custom styles',
        bgColor: '#2ecc71',
        textColor: '#fff',
    })
});

[].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .map(element => new Tooltip(element));

[].slice.call(document.querySelectorAll('.modal'))
    .map(element => {
        element.addEventListener('shown.bs.modal', function () {
            element.querySelector('input:required')?.focus()
        });
    });

[].slice.call(document.querySelectorAll('.modal[data-bs-modal="show"]'))
    .map(element => new Modal(element).show());

$('[data-widget="glide"]').each(function () {
    const glide = new Glide(this, {
        autoplay: 2 * 1000,
        breakpoints: {
            960: { perView: 6 },
            640: { perView: 4 },
            480: { perView: 2 },
        },
        hoverpause: true,
        peek: 50,
        perView: 8,
        startAt: 0,
        type: 'carousel',
    });
    glide.mount();
});
